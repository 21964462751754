import React, { Component } from 'react';
import Page from './Page';
import _ from 'lodash';
import List from '@material-ui/core/List';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import VideoReviewListItem from '../components/VideoReviewListItem';
import { withNamespaces  } from 'react-i18next';


class ReviewDialog extends Component {
    
    
    render() {
        
        let { t } = this.props;
        
        return (
            <Dialog open={this.props.open}>
                <DialogTitle>{t('Thank You For Watching')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('Please take a moment to rate the videos and leave comments.')}
                        <br />
                        <br />
                    </DialogContentText>
                    <DialogActions>
                    <Button onClick={this.props.handleClose} color="primary" autoFocus>
                      {t('Continue')}
                    </Button>
                  </DialogActions>
                </DialogContent>
            </Dialog>
        )
    }
}

ReviewDialog = withNamespaces('translations')(ReviewDialog);

class ReviewPage extends Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            isDialogOpen: props.provider.id !== 17
        }
    }
    
    replayVideo = (playlistItemIndex) => {
        this.props.history.push('/videoplayer?review=' + playlistItemIndex);
    }
    
    handleClose = () => {
        this.setState({
            isDialogOpen: false
        });
    }
    
    render() {
        
        let styles = {
            categoryHeader: {
                textTransform: "Uppercase",
                paddingLeft: '1em',
                paddingTop: '.5em',
                paddingBottom: '1em',
                opacity: '.7',
                fontWeight: 500
            },
            button: {
                marginTop: '2em',
                maxWidth: '10em',
                alignSelf: 'center',
                paddingTop: '2em',
                paddingBottom: '2em'
            }
        }
        
        let videos = this.props.videos.filter( video => this.props.playlist.find( function(playlistItem, index)   {
            if (playlistItem[0] === video.id)  {
                video.sort = index; 
                return true
            } else {
                return false
            }}))
            
        
            
        let videosByCategoryies = _.groupBy(_.orderBy(videos, 'sort'), 'category_id');
        let categories = _.orderBy(this.props.categories, 'sort'); 
        let language = {
            "English" : "en",
            "Spanish" : "es"
        }
        
        let { t } = this.props;
        
        return (
            <Page>
                <AppBar position="static">
                <Toolbar>
                  <Typography style={styles.title} variant="h6" color="inherit">
                    Playlist Review
                  </Typography>
                </Toolbar>
              </AppBar>
                <List>
                    
                    {categories.map(category => {
                    if (typeof videosByCategoryies[category.id] !== 'undefined') { 
                        return (
                            <div key={category.id}>
                                <div style={styles.categoryHeader}>{category.name}</div>
                                <Divider />
                                {videosByCategoryies[category.id].map( (video, index) => {
                                    return <VideoReviewListItem 
                                        key={video.id} 
                                        index={index}
                                        video={video} 
                                        feedback={this.props.feedback}
                                        videoFile={this.props.videoFiles.find(videoFile => videoFile.video_id === video.id && videoFile.language === language[this.props.language])}
                                        handleUpdateComment={this.props.handleUpdateComment}
                                        handleReplayVideo={this.replayVideo}
                                        handleRateVideo={this.props.handleRateVideo} 
                                        provider={this.props.provider}/>
                                })}
                            </div>
                        )
                    }
                    })}
                </List>
                <Button style={styles.button} color="primary" size="large" variant="contained" onClick={this.props.handleFeedbackSubmit}>
                    {t('Continue')}
                </Button>
                <ReviewDialog open={this.state.isDialogOpen} handleClose={this.handleClose} />
            </Page>
        )
    }
}

export default withNamespaces('translations')(ReviewPage);