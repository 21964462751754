
const getCaptionsFromTime = (captions, setCaptionText, position ) => {


    //iterate through the caption keys

    //split the key on '-'
        //first item startTime
        //second item EndTime
    //if position greater than start time and less than EndTime
        //set the caption
    let captionsExistForTimeStamp = false;
    for (const [key, value] of Object.entries(captions)) {

        
        const times = key.split('-');
        let startTime = parseInt(times[0])
        const endTime = parseInt(times[1])

        if(startTime >= 300) {
            startTime = startTime - 300
        }

        if(position >= startTime && position <= endTime) {

            setCaptionText(value)
            captionsExistForTimeStamp = true;
        }
    }

    if(captionsExistForTimeStamp == false) {
        setCaptionText([])
    }
      
}


export default getCaptionsFromTime;