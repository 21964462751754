import React, { Component } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

class Classification extends Component {
    
    render() {
        
        let styles = {
            listItem: {
                padding: '16px 8px 16px 64px'
            }
        }
        
        return(
            <ListItem onClick={this.props.handleClick} style={styles.listItem} divider={true}>
                <ListItemText  primary={this.props.name} />
            </ListItem>
        )
    }
}

export default Classification;