import React, { Component, useState, useEffect } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import _ from 'lodash';

const options = {
    'English':  'en',
    'Spanish': 'es',
    'Tagalog': 'tl' ,
    'Traditional Chinese': 'zh' 
  };

const Video = (props) => {


    let videoFiles = _.filter(props.videoFiles, (vf) => {
        return vf.video_id == props.id
    })


    const [hasCaptions, setHasCaptions] = useState( true)

    useEffect( ()=> {

        if(props.captionLanguage !== 'None') {
        
            let doesHaveCaptions = (_.some(videoFiles, (vf)=> {
                
                return _.some(vf.captions, (c) => {
                    return c.language == options[props.captionLanguage]
                })
            }))
            if(typeof doesHaveCaptions !== 'undefined') {
                setHasCaptions(doesHaveCaptions)
            }
            
        } else {
            setHasCaptions(true)
        }
    },[props.captionLanguage])


    
    
    let handleClick = ()=> {
        props.handleAddVideoToPlaylist(props.id, props.categoryId);
    }
    

    let styles = {
        listItem: {
            padding: '16px 8px 16px 8px'
        }
    }
    
  
        
        return (
            
            <ListItem 
                style={styles.listItem} 
                divider={true}
                disabled={!hasCaptions} 
                onClick={hasCaptions ? handleClick : ()=> {}}>
                <ListItemIcon>
                    {props.isInPlaylist ? <CheckIcon /> : <AddIcon />}
                </ListItemIcon>
                {}
                <ListItemText  primary={props.name} secondary={!hasCaptions ? 'Video not available in selected caption language.' : ''} />
            </ListItem>
            
        )
    
}

export default Video;