import React, { Component } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PlayIcon from '@material-ui/icons/PlayCircleFilled';
import StarIcon from '@material-ui/icons/Star';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';


class ReviewStars extends Component {
    


    
    render() {
        
        let styles = {
            container: {
                display: "flex",
                flexDirection: "row"
            },
            starIcon: {
                fill: 'rgba(63, 81, 181, .3)',
                height: '32px',
                width: '32px',
                
            },
            starIconFull: {
                height: '32px',
                width: '32px',
                fill: 'rgba(63, 81, 181, 1)',
            }
        }
        
        let rating = this.props.rating;
        

        
        return(
            <div style={styles.container}>
                <IconButton onClick={ ()=> this.props.handleRateVideo(1) }>
                    <StarIcon style={rating > 0 ? styles.starIconFull : styles.starIcon} />
                </IconButton >
                <IconButton onClick={ ()=> this.props.handleRateVideo(2) }>
                    <StarIcon style={rating > 1 ? styles.starIconFull : styles.starIcon}   />
                </IconButton>
                <IconButton onClick={ ()=> this.props.handleRateVideo(3) }>
                    <StarIcon style={rating > 2 ? styles.starIconFull : styles.starIcon}    />
                </IconButton>
                <IconButton onClick={ ()=> this.props.handleRateVideo(4) }>
                    <StarIcon style={rating > 3 ? styles.starIconFull : styles.starIcon}    />
                </IconButton>
                <IconButton onClick={ ()=> this.props.handleRateVideo(5) }>
                    <StarIcon style={rating > 4 ? styles.starIconFull : styles.starIcon}    />
                </IconButton>
            </div>
        )
    }
}

class VideoReviewListItem extends Component {
 

    handleRateVideo = (rating) => {
        
        this.props.handleRateVideo(this.props.videoFile.id, rating);
    }

    render() {
        
        let { video, videoFile } = this.props;
        
        let styles = {

            listItem: {
                justifyContent: "space-between",
                backgroundColor: "white"
            },
            itemIconContainer: {
                display: 'flex',
                alignItems: 'center',
                width: '25%'
            },
            playIcon: {
                opacity: '.5',
                height: '48px',
                width: '48px',
                
            },
            commentField: {
                width: "40%"
            }
        }
        
        let feedback = this.props.feedback.find( fb => fb.video_file_id === this.props.videoFile.id)
        
        let providerId = this.props.provider.id;
        
        return (
            <ListItem divider style={styles.listItem} key={videoFile.id}>
                <div style={styles.itemIconContainer}>
                    <ListItemIcon onClick={ () => this.props.handleReplayVideo(this.props.index) } >
                        <PlayIcon style={styles.playIcon} />
                    </ListItemIcon>
                    {video.name}
                </div>
                {providerId !== 17 &&
                <ReviewStars 
                    handleRateVideo={this.handleRateVideo}
                    rating={feedback.rating}
                />}
                {providerId !== 17 &&
                <TextField 
                    placeholder="Compliments or suggestions?"
                    style={styles.commentField}
                    onChange={(e)=> this.props.handleUpdateComment(videoFile.id, e.target.value)}
                />}
            </ListItem>
            
        )
    }
}

export default VideoReviewListItem;