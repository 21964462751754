import React, { Component } from 'react';
import Login from './pages/Login';
import Main from './pages/Main';
import VideoPlayer from './pages/VideoPlayer';
import Review from './pages/Review';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Route } from 'react-router';
import { Router } from 'react-router-dom'
import { createHashHistory } from 'history';
import './App.css';
import { submitFeedback, updateData } from './lib/API';
import { withI18n, withNamespaces  } from "react-i18next";


var history = createHashHistory();

function getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });
    return vars;
}

class App extends Component {
  
  constructor(props) {
    super(props);
    var data = JSON.parse(window.localStorage.getItem('data'));
    
    let getVars = getUrlVars();
    let approvedVideosOnly = getVars.approved_videos_only || false
  
    this.state = {
      provider: null,
      videos: '',
      videoFiles: '',
      categories: '',
      classifications: '',
      language: 'English',
      captions: 'None',
      deviceId: '',
      playlist: [],
      feedback: [],
      approvedVideosOnly: approvedVideosOnly
    }
    
    if (data !== null) {
      var provider = JSON.parse(window.localStorage.getItem('provider'));
      var language = window.localStorage.getItem('language');
      var captions = window.localStorage.getItem('captions');
      var deviceId = JSON.parse(window.localStorage.getItem('deviceId'));
      if (language === null) {
        language = "English"
      }

      if (captions === null) {
        captions = "None"
      }
      
      data = JSON.parse(window.localStorage.getItem('data'));
      
      let videos = []
      if(typeof data.videos !== 'undefined') {
        videos = data.videos;
      }

      let feedback = []
      if(typeof data.feedback !== 'undefined') {
        feedback = data.feedback;
      }
      
      let categories = data.categories;
      
      if (approvedVideosOnly) {
        videos = this.filterApprovedVideos(videos);
        categories = categories.filter( category =>  {
          if( category.id === 3) {
            category.name = " "
            return true;
          } 
        });
      }
      
      this.state = {
        provider: provider,
        videos: videos,
        video_files: data.video_files,
        categories: categories,
        classifications: data.classifications,
        language: language,
        captions: captions,
        deviceId: deviceId,
        feedback: feedback,
        approvedVideosOnly: approvedVideosOnly
      }
    }
  }
  
  approvedVideoIds = [1, 2, 3, 4, 79, 35, 60, 61, 81, 6,  62, 68, 63, 42, 69, 33, 46, 8, 64]
  
  filterApprovedVideos = (videos) => {
    return videos.filter( video => {
      if(this.approvedVideoIds.includes(video.id)) {
        video.category_id = 3
        video.category_ids = [3]
        return true;
      } 
    }).sort( (a, b) => a.name < b.name ? -1 : 1 )
    
  }

  
  
  reauthenticationRequired = ()=> {
    let loginDate = JSON.parse(window.localStorage.getItem('login_date'));
    let currentDate = Date.now();
    
    if(currentDate - loginDate > 2592000000) {
      window.localStorage.setItem('deviceId', null);
      this.setState({
        deviceId: null
      })
    }
  }
  
  assignData = ()=> {
    var provider = JSON.parse(window.localStorage.getItem('provider'));
    var data = JSON.parse(window.localStorage.getItem('data'));
    
    let videos = data.videos
    let categories = data.categories;
    
    if(this.state.approvedVideosOnly) {
      videos = this.filterApprovedVideos(videos);

      console.log(videos)
      categories = categories.filter( category =>  {
          if( category.id === 3) {
            category.name = " "
            return true;
          } 
        });
    }
    
    this.setState({
      provider: provider,
      videos: videos,
      video_files: data.video_files,
      categories: categories,
      classifications: data.classifications,
      deviceId: JSON.parse(window.localStorage.getItem('deviceId'))
    })

  }
  
  assignLanguage = (language)=> {
    
    const options = {
      'English':  'en',
      'Spanish': 'es'
    };
    this.props.i18n.changeLanguage(options[language])
    window.localStorage.setItem('language', language);
    window.localStorage.setItem('captions', 'None');
    this.setState({
      language: language,
      captions: 'None',
    })
  }

  assignCaptions = (captions)=> {
    
    if(captions == "Spanish") {
        this.props.i18n.changeLanguage('es')
        window.localStorage.setItem('language', 'Spanish');
        window.localStorage.setItem('captions', captions);
        this.setState({
            captions: captions,
            language: 'Spanish',
        })
    } else {
        this.props.i18n.changeLanguage('en')
        window.localStorage.setItem('language', 'English');
        window.localStorage.setItem('captions', captions);
        this.setState({
            captions: captions,
            language: 'English',
        })
    }
    
  }
  
  handleAddVideoToPlaylist = (videoId, categoryId)=> {
    let playlist = this.state.playlist;
    
    if(playlist.find( playlistItem => playlistItem[0] === videoId)) {
      playlist = playlist.filter(playlistItem => playlistItem[0] !== videoId)
    } else {
      playlist.push([videoId,categoryId]);
    }
    this.setState({
      playlist: playlist
    })
  }
  
  handleRemoveVideoFromPlaylist = (videoId) => {
    
  }
  
  handleReactionClick = (reaction, videoId) => {
    let feedback = JSON.parse(JSON.stringify(this.state.feedback));
   
    
    feedback.push({
      video_file_id:  videoId,
      reaction: reaction,
      provider_id: this.state.provider.id,
      serial_number: this.state.deviceId,
      comment: '',
      rating: 0
    })
    
    this.setState({
      feedback: feedback
    })
    
  }
  
  handleFeedbackSubmit = ()=> {

    submitFeedback(this.state.feedback);

    history.push('/');
    
    
    
    this.setState({
      playlist: [],
      feedback: []
    })
  }
  
  handleUpdateComment = (id, comment)=> {
    
    let feedback = JSON.parse(JSON.stringify(this.state.feedback));
    
    feedback.forEach( (videoFeedback, index) => {
      if (videoFeedback.video_file_id === id) {
        feedback[index].comment = comment;
      }
      
    });
    
    this.setState({
      feedback
    })
    
  }
  
  handleRateVideo = (id, rating) => {
    let feedback = JSON.parse(JSON.stringify(this.state.feedback));
    
    feedback.forEach( (videoFeedback, index) => {
      if (videoFeedback.video_file_id === id) {
        feedback[index].rating = rating;
      }
      
    });
    
    this.setState({
      feedback
    })
  }
  
  componentDidMount = () => {
    
    if(this.state.videos !== '') {
      if(this.state.deviceId !== null) {
        updateData(this.state.deviceId, this.assignData);
      }
    }
    
    this.reauthenticationRequired();
  }
  
  clearPlaylist = () => {
    this.setState({
      playlist: []
    })
  }
  
  
  render() {
  
    let playlist = this.state.playlist;

    if(typeof playlist === 'undefined') {
        playlist = []
    }

    const theme = createMuiTheme({
      typography: {
        useNextVariants: true,
      },
      palette: {
        primary: {
          main: "#3f51b5",
          contrastText: "#fff"
        },
         secondary: {
           main: "#ff4081"
         }
      },
    });  

    let requireLogin = this.state.videos === '';
    
    let requireDeviceId = this.state.deviceId === null;
    
    const { t, i18n } = this.props;
    
    window.localStorage.setItem('data', JSON.stringify(this.state));

    return (
      <Router history={history}>
        <React.Fragment>
          <CssBaseline />
          <MuiThemeProvider theme={theme}>
            {requireLogin || requireDeviceId ?  (<Login
              assignData={this.assignData}
              requireDeviceId={requireDeviceId}
            />) : (
              <React.Fragment> 
                <Route 
                path="/" 
                exact 
                render={(props) => <Main {...props} 
                                        approvedVideosOnly={this.state.approvedVideosOnly}
                                        provider={this.state.provider} 
                                        assignLanguage={this.assignLanguage} 
                                        assignCaptions={this.assignCaptions} 
                                        categories={this.state.categories}
                                        classifications={this.state.classifications}
                                        videos={this.state.videos}
                                        videoFiles={this.state.video_files}
                                        language={this.state.language}
                                        captions={this.state.captions}
                                        deviceId={this.state.deviceId}
                                        handleAddVideoToPlaylist={this.handleAddVideoToPlaylist}
                                        handleRemoveVideoFromPlaylist={this.handleRemoveVideoFromPlaylist}
                                        playlist={playlist}
                                        clearPlaylist={this.clearPlaylist}

                                  />}
                />
                <Route 
                  path="/videoplayer"
                  exact
                  render={(props) => <VideoPlayer {...props} 
                                        videos={this.state.videos}
                                        videoFiles={this.state.video_files}
                                        categories={this.state.categories}
                                        language={this.state.language}
                                        assignCaptions={this.assignCaptions} 
                                        captions={this.state.captions}
                                        playlist={playlist}
                                        provider={this.state.provider} 
                                        feedback={this.state.feedback}
                                        handleReactionClick={this.handleReactionClick}
                                        goBack={this.goBack}
                                      /> }
                  />
                  <Route 
                  path="/review"
                  exact
                  render={(props) => <Review {...props} 
                                        videos={this.state.videos}
                                        videoFiles={this.state.video_files}
                                        categories={this.state.categories}
                                        language={this.state.language}
                                        playlist={playlist}
                                        provider={this.state.provider} 
                                        feedback={this.state.feedback}
                                        handleFeedbackSubmit={this.handleFeedbackSubmit}
                                        handleUpdateComment={this.handleUpdateComment}
                                        handleRateVideo={this.handleRateVideo}
                                      /> }
                  />
              </React.Fragment> 
            )}
          </MuiThemeProvider>
        </React.Fragment> 
      </Router>
    );
  }
}

export default withNamespaces('translations')(App);
