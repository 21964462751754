import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import BackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';

class HolvanAppBar extends Component {

    state = {
        anchorEl: null,
      };

    handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = event => {
        this.setState({ anchorEl: null });
    };
    

    handleLanguageChange = (language) => {
        if(typeof language === "string") {
            this.props.assignLanguage(language);
        }
        this.setState({ anchorEl: null });
    };

    handleCaptionsChange = (language) => {
        if(typeof language === "string") {
            this.props.assignCaptions(language);
        }
        this.setState({ anchorEl: null });
    };

    render() {

        let language = this.props.language;
        let captions = this.props.captions;
        
        const { anchorEl } = this.state;    
        const open = Boolean(anchorEl);
      
        let styles = {
            
            title: {
                justifySelf: 'center'
            },
            
            button: {
                color: '#fff',
                marginLeft: 'auto'
            },
            languageTitle: {
                paddingLeft: '16px',
                fontSize: 20
            },
            languageMenu: {
                marginRight: '64px',
                paddingRight: '16px',
                paddingLeft: '16px'
            },
            languageActive: {
                fontWeight: 'bold'
            }

        }
      
      return (
          <AppBar position="static">
            <Toolbar>
              {this.props.showBackIcon ? <IconButton color="inherit" onClick={this.props.handleBackIconClick}><BackIcon /></IconButton> : ''}
              <Typography style={styles.title} variant="h6" color="inherit">
                {this.props.providerName} - Patient Education
              </Typography>
              <Button
                  style={styles.button}
                  aria-owns={open ? 'menu-appbar' : undefined}
                  aria-haspopup="true"
                  variant='text'
                  onClick={this.handleMenu}
                >
                  {this.props.captions === 'None' ? this.props.language : this.props.captions + ' Captions' } <ArrowDropDownIcon />
              </Button>
              <Menu                  
                  id="simple-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={this.handleClose}
                >
                    <Grid 
                        container 
                        direction="row"  
                        style={styles.languageMenu}>
                        <Grid item xs={6}>
                            <p style={styles.languageTitle}>Audio</p>
                            <MenuItem 
                                style={language === 'English' ? styles.languageActive : {}}
                                onClick={ ()=> this.handleLanguageChange('English')}>
                                English
                            </MenuItem>
                            <MenuItem 
                                style={language === 'Spanish' ? styles.languageActive : {}}
                                onClick={()=> this.handleLanguageChange('Spanish')}>
                                Spanish
                            </MenuItem>
                        </Grid>
                        <Grid item xs={6}>
                            <p style={styles.languageTitle}>Captions</p>
                            <MenuItem 
                                style={captions === 'None' ? styles.languageActive : {}}
                                onClick={ ()=> this.handleCaptionsChange('None')}>
                                None
                            </MenuItem>
                            <MenuItem 
                                style={captions === 'English' ? styles.languageActive : {}}
                                onClick={ ()=> this.handleCaptionsChange('English')}>
                                English
                            </MenuItem>
                            <MenuItem 
                                style={captions === 'Spanish' ? styles.languageActive : {}}
                                onClick={()=> this.handleCaptionsChange('Spanish')}>
                                Spanish
                            </MenuItem>
                            <MenuItem 
                                style={captions === 'Tagalog' ? styles.languageActive : {}}
                                onClick={()=> this.handleCaptionsChange('Tagalog')}>
                                Tagalog
                            </MenuItem>
                            <MenuItem 
                                style={captions === 'Traditional Chinese' ? styles.languageActive : {}}
                                onClick={()=> this.handleCaptionsChange('Traditional Chinese')}>
                                Traditional Chinese
                            </MenuItem>
                        </Grid>
                    </Grid>
                </Menu>
            </Toolbar>
          </AppBar>
      )
    }
}

HolvanAppBar.propTypes = {
  providerName: PropTypes.string.isRequired,
};

export default HolvanAppBar;