import {getHost, axiosConfig} from '../config.js';
import axios from 'axios';
import {saveJson} from './Storage';


export function loginProvider(username, password, setLoadingFalse, setLoginError) {

    let credentials = {
        username: username,
        password: password
    }

    return axios.post(
        getHost() + 'providers/login', 
        credentials,
        axiosConfig())
    .then(response => {
        saveJson('provider', response.data);
        setLoadingFalse();
    })
    .catch(response => {
      setLoginError(response);
    })
}

export function setDeviceId(deviceId, setLoadingFalse, setDeviceIdError) {

    return axios.get(
        getHost() + 'tablets/' + deviceId, 
        axiosConfig())
    .then(response => {
        saveJson('deviceId', deviceId);
        saveJson('data', response.data);
        saveJson('login_date', Date.now());
        setLoadingFalse();
    })
    .catch(response => {
      setDeviceIdError(response);
    })
}

export function updateData(deviceId, updateState) {
    return axios.get(
        getHost() + 'tablets/' + deviceId, 
        axiosConfig())
    .then(response => {
        saveJson('data', response.data);
        updateState();
    })
}

export function submitFeedback(feedback) {
    console.log({serial_number: feedback[0].serial_number,
        feedback})
    axios.post(
        getHost() + 'video_files/rate', 
        {serial_number: feedback[0].serial_number,
            "_json": feedback},
        axiosConfig())
    .then(response => {
        //console.log(response);
    })
    .catch(response => {
      //console.log(response);
    })
}