import i18n from "i18next";
import { reactI18nextModule } from 'react-i18next';

const options = {
      'English':  'en',
      'Spanish': 'es' 
    };

i18n.use(reactI18nextModule).init({
  // we init with resources
  resources: {
    en: {
      translations: {
        "The video helped me understand.": "The video helped me understand.",
        "I have a couple of questions.": "I have a couple of questions.",
        "I don't understand.": "I don't understand.",
        "Before We Continue...": 'Before We Continue...',
        "Please take a moment to rate the videos and leave comments.": "Please take a moment to rate the videos and leave comments.",
        "Thank You For Watching" : "Thank You For Watching",
        "Continue" : "Continue",
        "Are you sure you wish to go back? This will clear the playlist." : "Are you sure you wish to go back? This will clear the playlist.",
        "Yes" : "Yes",
        "No" : "No",
        "Quit Playback" : "Quit Playback"
      }
    },
    es: {
      translations: {
        "The video helped me understand.": "El video me ayudó a entender.",
        "I have a couple of questions." : "Tengo un par de preguntas.",
        "I don't understand." : "No entiendo.",
        "Before We Continue..." : "Antes de Continuar...",
        "Please take a moment to rate the videos and leave comments." : "Tómese un momento para calificar los videos y dejar comentarios.",
        "Thank You For Watching" : "Gracias por ver",
        "Continue" : "Bueno",
        "Are you sure you wish to go back? This will clear the playlist." : "¿Seguro que quieres volver? Esto borrará la lista de reproducción.",
        "Yes" : "Sí",
        "No" : "No",
        "Quit Playback" : "Dejar de Reproducir"
      }
    }
  },
  fallbackLng: options[window.localStorage.getItem('language')] || "en",
  debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    formatSeparator: ","
  },

  react: {
    wait: true
  }
});

export default i18n;
