import React, { Component } from 'react';
import Page from './Page';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { loginProvider, setDeviceId } from '../lib/API';

function getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });
    return vars;
}

class Login extends Component {
    
    constructor(props) {
        super(props);
        let getVars = getUrlVars();
        
        if(Object.keys(getVars).length === 3) {
            console.log("the vars!");
        }
    
    
        this.state = {
            username: getVars.provider,
            password: getVars.pw,
            deviceId: getVars.device_id,
            isError: false,
            isLoading: false,
            isRequireDeviceId: false
        }
        
        
    }
    
    handleChange = name => event => {
        this.setState({
          [name]: event.target.value,
        });
     };
    
    handleSubmit = ()=> {
        this.setState({isLoading: true, isError: false})
        loginProvider(this.state.username, this.state.password, this.loginSuccess, this.loginError );
    }
    
     
    loginSuccess = ()=> {
        this.setState({isLoading: false, isRequireDeviceId: true})
    } 
    
    loginError = ()=> {
        this.setState({isLoading: false, isError: true})
    } 
      
      
    handleDeviceIdSubmit = ()=> {
        this.setState({isLoading: true, isError: false})
        setDeviceId(this.state.deviceId, this.setDeviceIdSuccess, this.setDeviceIdError );
    }
    
    setDeviceIdSuccess = ()=> {
        this.props.assignData();
    }
    
    setDeviceIdError = ()=> {
        this.setState({isLoading: false, isError: true})
    }
      
    catchReturn = (e)=> {
        if (e.key === 'Enter') {
          // Do code here
          this.handleSubmit();
        }
    }  
      
    catchReturnDeviceId = (e)=> {
        if (e.key === 'Enter') {
          // Do code here
          this.handleDeviceIdSubmit();
        }
    }    
    
    componentDidMount = ()=> {
        if(typeof this.state.username !== 'undefined') {
            this.setState({isLoading: true})
            loginProvider(this.state.username, this.state.password, ()=> {}, this.loginError );
            setDeviceId(this.state.deviceId, this.setDeviceIdSuccess, this.setDeviceIdError );
        }
    }
    render() {
        
        
        let styles = {
          container: {
                height: "100%"
            },
            form: {
              padding: "24px"  
            },
            title: {
                paddingBottom: "24px"
            }
        }
        
        return (
            <Page>
                <Grid
                    style={styles.container}
                    container
                    direction="row"
                    justify="center"
                    alignItems="center">
                    <Grid 
                        item 
                        container 
                        alignItems="center"
                        direction="column">
                        <Typography style={styles.title} align="center" variant="h3">Holvan Video Player</Typography>
                        {!this.state.isLoading ? (
                        <Paper>
                            {!this.state.isRequireDeviceId ? (
                            <form style={styles.form}>
                                <Typography align="center" variant="h5">Sign In</Typography>
                                {this.state.isError ? (
                                        <Typography align="center" color="error" variant="body1">Invalid Username/Password</Typography>
                                ) : ''}
                                <TextField
                                  id="username"
                                  label="Username"
                                  value={this.state.username}
                                  onChange={this.handleChange('username')}
                                  margin="normal"
                                  fullWidth
                                />
                                <br />
                                <TextField
                                  id="password"
                                  label="Password"
                                  value={this.state.password}
                                  onChange={this.handleChange('password')}
                                  margin="normal"
                                  type="password"
                                  fullWidth
                                  onKeyPress={this.catchReturn}
                                />
                                <br />
                                <br />
                                <Button fullWidth={true} variant="contained" color="primary" onClick={this.handleSubmit}>
                                    Sign In
                                </Button>


                            </form>
                            ) : (
                                <form style={styles.form}>
                                    <Typography align="center" variant="h5">Set Device Id</Typography>
                                    {this.state.isError ? (
                                        <Typography align="center" color="error" variant="body1">Invalid Device Id</Typography>
                                ) : ''}
                                    <TextField
                                      id="deviceId"
                                      label="Device Id"
                                      value={this.state.deviceId}
                                      onChange={this.handleChange('deviceId')}
                                      margin="normal"
                                      fullWidth
                                      onKeyPress={this.catchReturnDeviceId}
                                    />
                                    <br />
                                    <br />
                                    <Button fullWidth={true} variant="contained" color="primary" onClick={this.handleDeviceIdSubmit}>
                                        Submit
                                    </Button>
                                </form>
                            )}
                        </Paper>
                        ) : (
                            <div style={styles.loadingContainer}>
                              <CircularProgress />
                            </div>
                        )}
                    </Grid>
                </Grid>
            </Page>
        );
    }
}

export default Login;
