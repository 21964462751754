const storage = window.localStorage;

export function saveJson(key, data) {
    storage.setItem(key, JSON.stringify(data));
}



export function getPatientData() {
    return {
        id: storage.getItem('patient_id'),
        first_name: storage.getItem('first_name'),
        last_name: storage.getItem('last_name'),
        email: storage.getItem('email'),
        phone: storage.getItem('phone'),
        date_of_birth: storage.getItem('date_of_birth')
    }
}


