import * as React from 'react';
import getCaptionsFromTime from './getCaptionsFromTime'
import _ from 'lodash';

const Captions = ({position, captions, captionsLanguange}) => {

    const options = {
        'English':  'en',
        'Spanish': 'es',
        'Tagalog': 'tl' ,
        'Traditional Chinese': 'zh' 
      };

    const [captionText, setCaptionText] = React.useState([]);


    const [selectedCaptions, setSelectedCaptions] = React.useState(JSON.parse(_.find(captions, {'language': options[captionsLanguange]}).json));

    React.useEffect( ()=> {
        getCaptionsFromTime(selectedCaptions, setCaptionText, position)
    }, [position])


    React.useEffect( ()=> {
        setSelectedCaptions(JSON.parse(_.find(captions, {'language': options[captionsLanguange]}).json))
    }, [captionsLanguange, captions])


    var styles = {
        captionWrapper: {
            position: "absolute",
            bottom: 10,
            width: '100%',
            flexDirection: "column",
            justifyContent: "center",
            alignItems: 'center',
            textAlign: 'center'
        },
        text: {
            color: "#fff",
            backgroundColor: "#000000",
            fontSize: 28,
            padding: 5,
            alignItems: 'center',
            justifyContent: "center",
        }
    }


    return (
        <div style={styles.captionWrapper}>
            {captionText.map( (caption, index) => (
                <span style={styles.text} key={index} >{caption}<br /></span>
            ))}
        </div>
    )
}


export default Captions;
