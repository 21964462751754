import React, { Component } from 'react';
import Video from './Video';
import Classification from './Classification';
import List from '@material-ui/core/List';

class Category extends Component {


    
    render() {

        
        let hasClassifications = this.props.classifications.length > 0;
        let classificationId = this.props.selectedClassification;
        
        
        let videos = classificationId === null ? this.props.videos : this.props.videos.filter( (video)=> video.classification_id === classificationId);
        
        if(hasClassifications) {
            this.props.classifications.sort( (a,b) => {
                if(a.name < b.name) { return -1; }
                if(a.name > b.name) { return 1; }
                return 0;
            });
        }

        return (
            <div>
                <List>

                    {hasClassifications && classificationId === null ? (
                            this.props.classifications.map( classification => {
                                return <Classification key={classification.id} {...classification} handleClick={ ()=> this.props.handleClassificationSelect(classification.id)} />
                            })
                        ) : ((videos.map( video => {
                                return <Video 
                                    categoryId={this.props.category.id}
                                    language={this.props.language}
                                    captionLanguage={this.props.captions}
                                    videoFiles={this.props.videoFiles}
                                    key={video.id} 
                                    isInPlaylist={this.props.playlist.find( (playlistVideo) => playlistVideo[0] === video.id)}
                                    handleAddVideoToPlaylist={this.props.handleAddVideoToPlaylist}
                                    handleRemoveVideoFromPlaylist={this.props.handleRemoveVideoFromPlaylist}
                                    {...video} />
                            })
                            )
                        )
                        
                    }
                </List>
            </div>
        )
    }
}

export default Category;