import React, { Component } from 'react';
import Fade from '@material-ui/core/Fade';
import { withTheme } from '@material-ui/core/styles';

class Page extends Component {
    
    render() {

        var styles = {
            container: {
                height: "100%",
                display: 'flex',
                flexDirection: 'column'
            }
        }    
        
        return (
          <Fade in={true} mountOnEnter unmountOnExit>
            <div style={styles.container}>
                {this.props.children}
            </div>
          </Fade>
        );
    }
}

export default withTheme()(Page);