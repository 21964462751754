import React, { Component } from 'react';
import Page from './Page';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import AppBar from '../components/AppBar';
import Tabs from '../components/Tabs';
import Playlist from '../components/Playlist';
import Category from '../components/Category';
import _ from 'lodash';

class Main extends Component {
    
    constructor(props) {
        super(props);
        props.clearPlaylist();
    
   
    }
    
    
    
    state = {
        selectedCategory: this.props.categories.sort((a, b)=> a.sort - b.sort)[0].id,
        selectedClassification: null,
        showBackIcon: false,

    }
    
    handleTabChange = (event, value) => {
        this.setState({ 
            selectedCategory: value, 
            selectedClassification : null,
            showBackIcon: false
        });
    }
    
    handleClassificationSelect = (classificationId) => {
        this.setState({ 
            selectedClassification: classificationId, 
            showBackIcon: true
        });
    }
    
    handleBackIconClick = () => {
        this.setState({ 
            selectedClassification: null, 
            showBackIcon: false
        });
    }
    
    render() {
        
        let styles = {
            gridContainer: {
                flexDirection: 'row',
                flex: 1
            },
            column: {
                display: 'flex',
                flexDirection: 'column',
                flex: 1
            }
        }
        
        
        return(
            <Page>
                <AppBar 
                    providerName={this.props.provider.name} 
                    language={this.props.language}
                    captions={this.props.captions}
                    assignLanguage={this.props.assignLanguage}
                    assignCaptions={this.props.assignCaptions}
                    showBackIcon={this.state.showBackIcon}
                    handleBackIconClick={this.handleBackIconClick}
                    />
                <Grid
                    container
                    direction="row"
                    style={styles.gridContainer}
                    >
                    <Grid
                        style={styles.column}
                        item 
                        xs={8}>
                            <Tabs
                                selectedCategory={this.state.selectedCategory}
                                handleTabChange={this.handleTabChange}
                                categories={this.props.categories}
                                approvedVideosOnly={this.props.approvedVideosOnly}
                            />
                            <Category
                                videoFiles={this.props.videoFiles}
                                handleAddVideoToPlaylist={this.props.handleAddVideoToPlaylist}
                                handleRemoveVideoFromPlaylist={this.props.handleRemoveVideoFromPlaylist}
                                playlist={this.props.playlist} 
                                language={this.props.language}
                                captions={this.props.captions}
                                videos={this.props.videos.filter( (video)=> _.includes(video.category_ids, this.state.selectedCategory))}
                                classifications={this.props.classifications.filter( (classification)=> classification.category_id === this.state.selectedCategory)}
                                category={this.props.categories.find( (category)=> category.id === this.state.selectedCategory)}
                                selectedClassification={this.state.selectedClassification}
                                handleClassificationSelect={this.handleClassificationSelect}
                            />
                    </Grid>
                    <Grid
                        style={styles.column}
                        item 
                        xs={4}>
                        <Playlist 
                            playlist={this.props.playlist}
                            categories={this.props.categories}
                            deviceId={this.props.deviceId}
                            videos={this.props.videos}
                            handleGoToVideoPlayer={ ()=> this.props.history.push('/videoplayer') }
                        />
                    </Grid>
                </Grid>
                
            </Page>
        )
    }
}

export default Main;