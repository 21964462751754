import React, { Component } from 'react';
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { withStyles } from '@material-ui/core/styles';


const classes = {
  selected: {
    color: "#ff4081",
  },
};

class HolvanTabs extends Component {
    
    render() {
        
        const { classes } = this.props;
        
        let categories = this.props.categories.sort( (a, b) =>  {
           if(a.sort < b.sort) {
             return -1
           } else {
             return 1
           }
          })
        
    
        return (
          <Tabs style={this.props.approvedVideosOnly ? {display:"none"} : {}} value={this.props.selectedCategory} variant="fullWidth" onChange={this.props.handleTabChange}>
            {categories.map( (category) => {
                return <Tab classes={{selected: classes.selected}} value={category.id}  key={category.id} label={category.name} />
            })}
          </Tabs>
         )
    }
}

export default withStyles(classes)(HolvanTabs);