import React, { Component } from 'react';
import _ from 'lodash';
import Fab from '@material-ui/core/Fab';
import PlayIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import ReplayIcon from '@material-ui/icons/Replay10';
import BackIcon from '@material-ui/icons/ArrowBack';
import LinearProgress from '@material-ui/core/LinearProgress';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import { withNamespaces  } from 'react-i18next';
import Captions from '../components/captions';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Grid from '@material-ui/core/Grid';

const langaugeOptions = {
    'English':  'en',
    'Spanish': 'es',
    'Tagalog': 'tl' ,
    'Traditional Chinese': 'zh' 
  };


class BackDialog extends React.Component {
    
  handleClose = () => {
    this.props.onClose();
  };

  handleGoBack = value => {
    this.props.goBack();
  };

  render() {

    let { t } = this.props;

    return (
      <Dialog onClose={this.handleClose} open={this.props.open} aria-labelledby="simple-dialog-title">
        <DialogTitle>{t('Quit Playback')}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t('Are you sure you wish to go back? This will clear the playlist.')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" autoFocus>
              No
            </Button>
            <Button onClick={this.handleGoBack} color="primary" >
              {t('Yes')}
            </Button>
          </DialogActions>
      </Dialog>
    );
  }
}

BackDialog = withNamespaces('translations')(BackDialog);

class FeedbackDialog extends React.Component {
    
    

  handleClose = () => {
    this.props.onClose();
  };

  render() {
    let styles = {
        button: {
            display: "flex",
            flexDirection: "column",
            width: "30%",
            padding: "1em",

            fontSize: '1.1em',
            textTransform: 'none'
        },
        buttonContainer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
        },
        buttonPositive: {
            color: "#388e3c",
            borderColor: "#388e3c"
        },
        buttonNeutral: {
            color: "#f9a825",
            borderColor: "#f9a825"
        },
        buttonNegative: {
            color: "#c52928",
            borderColor: "#c52928"
        },
        title: {
            textAlign: "center"
        }
        
    }
    
    let { t } = this.props;

    return (
      <Dialog disableBackdropClick={true} disableEscapeKeyDown={true} onClose={this.handleClose} open={this.props.open} aria-labelledby="simple-dialog-title">
        <DialogTitle style={styles.title}>{t('Before We Continue...')}</DialogTitle>
        <DialogContent>
            <div style={styles.buttonContainer}>
                <Button size="large" style={{...styles.buttonPositive, ...styles.button}}  variant="outlined" onClick={() => this.props.handleReactionClick(1, this.props.currentVideoFileId) }>
                    <div>
                        <img src="/img/happy-emoticon.png" />
                        <br />
                        {t('The video helped me understand.')}
                    </div>
                </Button >
                <Button style={{...styles.buttonNeutral, ...styles.button}}   variant="outlined" onClick={() => this.props.handleReactionClick(2, this.props.currentVideoFileId) }>  
                    <div>
                        <img src="/img/neutral-emoticon.png" />
                        <br />
                        {t('I have a couple of questions.')}
                    </div>
                </Button >
                <Button style={{...styles.buttonNegative ,...styles.button}}   variant="outlined" onClick={() => this.props.handleReactionClick(3, this.props.currentVideoFileId) }>
                    <div>
                        <img src="/img/sad-emoticon.png" />
                        <br />
                        {t("I don't understand.")}
                    </div>
                </Button >
            </div>
          </DialogContent>
      </Dialog>
    );
  }
}

FeedbackDialog = withNamespaces('translations')(FeedbackDialog);

class VideoPlayer extends Component {
    
    
    constructor(props) {
        super(props);
        let language = {
            "English" : "en",
            "Spanish" : "es"
        }
        
        let videos = props.videos.filter( video => props.playlist.find( function(playlistItem, index)   {
            if (playlistItem[0] === video.id)  {
                video.sort = index; 
                let file = props.videoFiles.find(videoFile => videoFile.video_id === video.id && videoFile.language === language[props.language]);
                if(file.url.indexOf('https') === -1) {
                    video.url = file.url.replace('http', 'https');
                } else {
                    video.url = file.url
                }
                video.fileId = file.id;
                video.captions = []
                if(file.captions.length > 0) {

                    /*
                    let foundCaptions = _.find(file.captions, {'language': langaugeOptions[props.captions]})

                    if(typeof foundCaptions !== 'undefined') {
                        video.captions = foundCaptions.json
                    }
                    */

                    video.captions = file.captions

                }
                return true
            } else {
                return false
            }}))
            
            
            
        let videosByCategoryies = _.groupBy(_.orderBy(videos, 'sort'), 'category_id');
        let categories = _.orderBy(props.categories, 'sort'); 
        var playlist = []
        

        
        categories.forEach( category => {
            
            if (typeof videosByCategoryies[category.id] !== 'undefined') { 
                videosByCategoryies[category.id].forEach( video => {
                   playlist.push({
                        "mediaid": video.fileId,
                        "file": video.url,
                        "image": "", 
                        "title": video.name,
                        "captions": video.captions
                    })
                                    
                })
            }
            
            
        })

        
    
        this.state = {
            playlist: playlist,
            showControls: false,
            isPlaying: true,
            duration: 100,
            position: 0,
            isDialogOpen: false,
            isFeedbackDialogOpen: false,
            currentVideoFileId: playlist[0],
            currentVideoFileIndex: 0,
            isComplete: false,
            positionSeconds: 0,
            anchorEl: null,
            captions: {}
        } 
        
        this.isRadltd = this.props.provider.id === 17
    }
    
    
    componentDidMount() {
        
        
        
        
        window.jwplayerInstance = window.jwplayer("jwplayer");
        
        let playlist = this.state.playlist;
        
        let isReview = this.props.location.search.length > 0;
        
        if (isReview) {
            playlist = playlist[this.props.location.search.split("=")[1]];    
        } 
        
        
        window.jwplayerInstance.setup({
          "autostart": true,
          "stretching": "uniform",
          "height": "100%",
          "width": "100%",
          "displaytitle": false,
          "controls": false,
          "playlist" : playlist
        });

        window.jwplayer().on('setupError', (error)=> {
            console.log(error);
        })
        
    
        
        window.jwplayer().on('displayClick', (e) => {
            this.showControls();
        })

        window.jwplayer().on('firstFrame', () => {

            this.setState({ 
                currentVideoFileIndex: window.jwplayer().getPlaylistIndex(),
                captions: this.state.playlist[window.jwplayer().getPlaylistIndex()].captions
            })

            if(!isReview) {
            
                let item = window.jwplayer().getPlaylistItem(window.jwplayer().getPlaylistIndex() - 1);

                if( window.jwplayer().getPlaylistIndex() !== 0) {
    
                    
                    window.jwplayer().pause();
                    this.setState({ 
                        isFeedbackDialogOpen: true,
                        currentVideoFileId: item.sources[0].mediaid,
                    })
                }
            }
        })

        

        window.jwplayer().on('playlistComplete', () => {
            
            window.jwplayer().pause();
            if(isReview) {
                this.props.history.push("/review"); 
            } else {
                this.setState({
                    isFeedbackDialogOpen: true,
                    currentVideoFileId: window.jwplayer().getPlaylistItem().sources[0].mediaid,
                    isComplete: true
                })
            }
        })

    
        window.jwplayer().on('time', ({duration, position})=> {
            this.setState({
                position: position/duration * 100,
                positionSeconds: position
            })
        })

        let jwplayerElement = document.querySelector('#jwplayer');
        
        this.jwplayerElementWidth = jwplayerElement.offsetWidth;
        this.jwplayerElementHeight = jwplayerElement.offsetHeight;
        
        
        

    }
    
    showControls() {
        
        this.setState({
            showControls: true
        });
        
        setTimeout( ()=>  {
            this.setState({
                showControls: false
            });
        }, 5000);
    }
    
    togglePlayPause = ()=> {
        
        let isPlaying  = !this.state.isPlaying;
        
        if(isPlaying) {
            window.jwplayer().play();
        } else {
            window.jwplayer().pause();
        }
        
        this.setState({
            isPlaying: isPlaying
        })
        
    }
    
    rewind = ()=> {
        let position = window.jwplayer().getPosition();
        
        window.jwplayer().seek(position - 10);
    }
    
    skip = ()=> {
        
        let position = window.jwplayer().getPosition();
        
        window.jwplayer().seek(position + 30);
    }
    
    handleGoBack = ()=> {
        this.props.history.goBack();
    }
    
    handleCloseDialog = ()=> {
        this.setState({
            isDialogOpen: false
        })
    }
    
    handleFeedbackCloseDialog = ()=> {
        this.setState({
            isFeedbackDialogOpen: false
        })
    }
    
    handleReactionClick = (reaction, video_id) => {
        this.props.handleReactionClick(reaction, video_id);
        
        if(this.state.isComplete) {
            this.props.history.push("/review");
        } else {
            window.jwplayer().play();
        }
        this.setState({
            isFeedbackDialogOpen: false
        });
        
    }
    
    handleCaptionsChange = (language) => {



        if(typeof language === "string") {
            this.props.assignCaptions(language);
        }
        this.setState({ anchorEl: null });
    };

    handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = event => {
        this.setState({ anchorEl: null });
    };


    render() {
        
        let pixelRatio = window.devicePixelRatio;
        let h = window.innerHeight;
        let w = window.innerWidth;
        const { anchorEl } = this.state;    
        const open = Boolean(anchorEl);

        let warning = "There may be differences between how the procedure is performed in this educational video and how it is performed at Radiology Ltd";
        
        if (this.props.language === "Spanish") {
            warning = "Puede haber diferencias entre cómo se realiza el procedimiento en este video educativo y cómo se realiza en Radiology Ltd"
        }
        
        let styles = {
            container: {
                height: "100%",
                width: "100%"
            },
            logo: {
                position: "absolute",
                top: "2em",
                left: "2em",
                zIndex: 99
            },
            controls: {
                display: this.state.showControls ? "flex" : "none",
                position: "fixed",
                bottom: ".2em",
                width: "100%",
                zIndex: 999,
                padding: '.5em'
            },
            backButtonContainer: {
                display: this.state.showControls ? "flex" : "none",
                position: "absolute",
                top: 0,
                width: "100%",
                zIndex: 999,
                padding: '.5em'
            },
            playbButton: {
                marginRight: '.5em'
            },
            progressContainer: {
                position: "fixed",
                bottom: 0,
                width: "100%",
                zIndex: 99
            },
            warning: {
                background: "black",
                color: "white",
                zIndex: 99,
                left: "50%",
                transform: "translateX(-50%)",
                top: "calc(50% - 1em)",
                padding: "1em",
                position: "absolute",
                opacity: this.isRadltd ? 1 : 0,
                display: (this.state.positionSeconds < 10 ? "block" : "none")
            },
            languageTitle: {
                paddingLeft: '16px',
                paddingRight: '16px',
                fontSize: 20
            },
            languageActive: {
                fontWeight: 'bold'
            },
            captionsButton: {
                float: 'right',
                marginLeft: 'auto'
            }
        }
        
        return(
            <div style={styles.container}>
                <img style={styles.logo} alt="logo" src={this.props.provider.logo} />
                <div style={styles.backButtonContainer} >
                    <Fab color="primary" onClick={()=> this.setState({isDialogOpen: true})}>
                        <BackIcon />
                    </Fab>
                </div>
                <div style={styles.controls}>
                    <Fab color="primary" style={styles.playbButton} onClick={this.togglePlayPause}>
                        {this.state.isPlaying ? <PauseIcon /> : <PlayIcon />}
                    </Fab>
                    <Fab color="primary" onClick={this.rewind} >
                        <ReplayIcon />
                    </Fab>
                    {this.props.provider.id === 6 &&
                    <Fab color="primary" onClick={this.skip} >
                        <ReplayIcon />
                    </Fab>}
                    {this.state.playlist[this.state.currentVideoFileIndex].captions.length > 0 &&
                        <Button 
                            color="primary" 
                            variant='contained' 
                            style={styles.captionsButton}
                            onClick={this.handleMenu}
                            >
                            Captions - {this.props.captions}
                        </Button>
                    }  
                    {this.state.playlist[this.state.currentVideoFileIndex].captions.length > 0 && 
                    <Menu                  
                        id="simple-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={this.handleClose}
                        >
                            <Grid 
                                container 
                                direction="row"  
                                >
                                <Grid item xs={12}>
                                    <p style={styles.languageTitle}>Captions</p>
                                    <MenuItem 
                                        style={this.props.captions === 'None' ? styles.languageActive : {}}
                                        onClick={ ()=> this.handleCaptionsChange('None')}>
                                        None
                                    </MenuItem>
                                    {_.some(this.state.playlist[this.state.currentVideoFileIndex].captions, {'language': 'en'}) &&
                                        <MenuItem 
                                            style={this.props.captions === 'English' ? styles.languageActive : {}}
                                            onClick={ ()=> this.handleCaptionsChange('English')}>
                                            English
                                        </MenuItem>
                                    }
                                    {_.some(this.state.playlist[this.state.currentVideoFileIndex].captions, {'language': 'es'}) &&
                                        <MenuItem 
                                            style={this.props.captions === 'Spanish' ? styles.languageActive : {}}
                                            onClick={()=> this.handleCaptionsChange('Spanish')}>
                                            Spanish
                                        </MenuItem>
                                    }
                                    {_.some(this.state.playlist[this.state.currentVideoFileIndex].captions, {'language': 'tl'}) &&
                                        <MenuItem 
                                            style={this.props.captions === 'Tagalog' ? styles.languageActive : {}}
                                            onClick={()=> this.handleCaptionsChange('Tagalog')}>
                                            Tagalog
                                        </MenuItem>
                                    }
                                    {_.some(this.state.playlist[this.state.currentVideoFileIndex].captions, {'language': 'zh'}) &&
                                        <MenuItem 
                                            style={this.props.captions === 'Traditional Chinese' ? styles.languageActive : {}}
                                            onClick={()=> this.handleCaptionsChange('Traditional Chinese')}>
                                            Traditional Chinese
                                        </MenuItem>
                                    }
                                </Grid>
                            </Grid>
                        </Menu>
                    
                        }
                </div>
                <div style={styles.progressContainer}>
                    <LinearProgress variant="determinate" color="secondary" value={this.state.position} />
                </div>
                <div id="warning" style={styles.warning}>{warning}</div>
                <div id="jwplayer"></div>
                <BackDialog 
                    open={this.state.isDialogOpen}
                    goBack={this.handleGoBack}
                    onClose={this.handleCloseDialog}
                />
                <FeedbackDialog 
                    handleReactionClick={this.handleReactionClick}
                    open={this.state.isFeedbackDialogOpen}
                    onClose={this.handleFeedbackCloseDialog}
                    currentVideoFileId={this.state.currentVideoFileId}
                />
                {this.props.captions != "None" && 
                typeof this.state.playlist[this.state.currentVideoFileIndex] !== 'undefined' &&
                this.state.captions.length > 0 && <Captions 
                    currentVideoFileIndex={this.state.currentVideoFileIndex}
                    captionsLanguange={this.props.captions}
                    position={this.state.positionSeconds*1000} 
                    captions={this.state.captions} 
                />}
            </div>
        )
    }
}

export default withNamespaces('translations')(VideoPlayer);