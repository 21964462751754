import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import _ from 'lodash';
import Fab from '@material-ui/core/Fab';
import PlayIcon from '@material-ui/icons/PlayArrow';

class Playlist extends Component {
    
    render() {

        
        
        let styles = {
            container: {
                backgroundColor: '#fdfdfd',
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                padding: '8px 8px 8px 16px'
            },
            deviceIdContainer: {
                display: 'flex',
                marginTop: 'auto',
                alignSelf: 'flex-end'
            },
            deviceId: {
                display: 'flex',   
                opacity: '.5',
                fontSize: '.8em',
                padding: '8px'
            },
            categoryHeader: {
                textTransform: "Uppercase",
                fontWeight: 500,
                fontSize: '1em',
                opacity: '.7',
                paddingBottom: '1em',
                paddingTop: '.5em'
            },
            video: {
                marginLeft: '2em',
                fontSize: '1em',
                opacity: '.6',
                paddingBottom: '1em'
            },
            playButton: {
                position: "absolute",
                bottom: "2em",
                right: "2em"
            }
        }
        
        let videos = this.props.videos.filter( video => this.props.playlist.find( function(playlistItem, index)   {
            if (playlistItem[0] === video.id)  {
                video.sort = index; 
                video.categoryId = playlistItem[1]
                return true
            } else {
                return false
            }}))
            
            let videosByCategoryies = _.groupBy(_.orderBy(videos, 'sort'), 'category_id');
            let categories = _.orderBy(this.props.categories, 'sort'); 
            let isDisplayButton = videos.length > 0;
            
            return (
                <Paper style={styles.container}>
                    {categories.map(category => {
                        if (typeof videosByCategoryies[category.id] !== 'undefined') { 
                            return (
                                <div key={category.id}>
                                    <div style={styles.categoryHeader}>{category.name}</div>
                                    {videosByCategoryies[category.id].map( video => {
                                        return (
                                            <div style={styles.video} key={video.id}>
                                                {video.name}
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        }
                        })}
                    {isDisplayButton && <Fab color="primary" style={styles.playButton} onClick={this.props.handleGoToVideoPlayer}>
                        <PlayIcon />
                    </Fab>}
                    <span style={styles.deviceIdContainer}><span style={styles.deviceId}>{this.props.deviceId}</span></span>
                </Paper>
            )
    }
}

export default Playlist;