var token, host;

var getHost = function() {

    let host;

    if (typeof window.host === 'undefined') {
        host = 'http://192.168.1.137:3000/';
        return host;
    }
    else {
        return window.host;
    }

}



var axiosConfig = function() { return { headers: {'Content-Type': 'application/json', 'Accept': 'application/json'} } }

export {
    getHost,
    axiosConfig
}
